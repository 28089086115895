<template>
  <div class="lead-detail">
    <div class="d-flex justify-content-between mb-4">
      <div>
        <LeadPurpose
          v-if="lead.purpose"
          :purpose="lead.purpose"
          class="px-4 py-1"
          :style="{ width: 'fit-content' }"
        ></LeadPurpose>
      </div>
      <div>
        <LeadStatus :status="lead.status" class="px-4 py-1"></LeadStatus>
      </div>
    </div>
    <div
      v-if="lead.expireAt"
      class="expiry-date mb-3"
      :style="{ width: 'fit-content' }"
    >
      Expires on
      <span class="d-block font-bold" :style="{ fontSize: '20px' }">
        {{ $dayjs(lead.expireAt).format("DD-MM-YYYY") }}
      </span>
    </div>

    <fd-form-section title="Contact Person" class="bordered-card p-2">
      <display-data
        class="col-12 md-col-6 xl-col-3"
        label="Name"
        :content="lead.name"
      ></display-data>
      <display-data
        v-if="isTaken && isMine"
        class="col-12 md-col-6 xl-col-3"
        label="Contact"
        :content="lead.contact"
      ></display-data>

      <!-- Hint Info -->
      <div
        v-if="isPublic"
        class="col-12 p-2 bg-blue-700 fg-white"
        :style="{ borderRadius: '4px' }"
      >
        <i class="fas fa-info-circle mr-1"></i>

        <template v-if="isCapped">
          You have already reach the limit of leads to follow up (max 3 leads).
        </template>
        <template v-else>
          This lead is currently open to public. Follow up this lead to see the
          contact info of this lead.
        </template>
      </div>
      <div
        v-if="isTaken && !isMine"
        class="col-12 p-2 bg-warn fg-grey-800"
        :style="{ borderRadius: '4px' }"
      >
        <i class="fas fa-info-circle mr-1"></i>
        This lead is currently being followed up by other agent. You might have
        to wait until it's open to public again to follow up this lead.
      </div>
    </fd-form-section>

    <fd-form-section title="Lead Details">
      <display-data
        class="col-12"
        label="Address"
        :content="leadAddress"
      ></display-data>
      <display-data
        v-if="isTaken && isMine"
        class="col-12"
        label="Property Code"
        :content="lead.propertyCode"
      ></display-data>
      <display-data
        class="col-12"
        label="Date Posted"
        :content="
          lead.createdAt ? $dayjs(lead.createdAt).format('DD-MM-YYYY') : null
        "
      ></display-data>
    </fd-form-section>

    <fd-form-section title="Property Details">
      <display-data
        class="col-12"
        label="Property Category"
        :content="lead.propertyCategory"
      ></display-data>
      <display-data
        class="col-12"
        label="Property Type"
        :content="_.get(lead, 'propertyType.name')"
      ></display-data>
    </fd-form-section>
  </div>
</template>

<script>
import { leadStatus } from "@/enums";
import { getAddress } from "@/modules/Lead/utils";

export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    LeadPurpose: () => import("@/modules/Lead/components/LeadPurpose"),
    LeadStatus: () => import("@/modules/Lead/components/LeadStatus")
  },
  mixins: [],
  props: {
    lead: {
      type: Object
    },
    isCapped: {
      type: Boolean
    }
  },
  data: function () {
    return {};
  },
  computed: {
    leadAddress() {
      return getAddress(this.lead);
    },
    isPublic() {
      return this.lead.status === leadStatus.PENDING;
    },
    isTaken() {
      return this.lead.status === leadStatus.ACCEPTED;
    },
    isMine() {
      return this.lead.agent?.user?.id == this.$auth.user().id;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.lead-detail {
  .expiry-date {
    border: solid 1px $color-danger;
    color: $color-danger;
    border-radius: 5px;
    padding: 8px 20px;
  }
}
</style>
